<template>
    <div class="member">
        <img @mouseover="hoverPicture"
            @mouseout="outPicture"
            class="member-image"
            :v-if="dataSrc"
            :src="getImgPath() + dataSrc[currentSrc]" />

        <div class="member-info">
            <div class="name">
                {{name}}
            </div>
            <div class="resort">
                {{resort}}
            </div>
            <div class="phone">
                {{phone}}
            </div>
            <div class="email">
                {{email}}
            </div>
        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'
    export default {
    	voVueComponent: 'voffice-team-member',
    	data() {
    		return {
    			currentSrc: 0
    		}
    	},
    	props: {
    		name: String,
    		phone: String,
    		email: String,
    		image: String,
    		resort: String,
    		dataSrc: Array,
    		path: String,
    	},
    	computed: {},

    	methods: {
    		getImgPath: function() {
    			return this.path.replace('berit.jpg', '');
    		},
    		hoverPicture: function() {
    			this.currentSrc = 1;
    		},
    		outPicture: function() {
    			this.currentSrc = 0;
    		}
    	},
    }
</script>



<style scoped>
    .member {
    	display: flex;
    	flex-direction: column;
    	border: 1px solid #ddd;
    	border-radius: 4px;
    }

    .member-image {
    	width: 100%;
    	height: auto;
    	display: block;
    	margin: 0 auto;
    	max-height: 340px;
    	object-fit: cover;
    	object-position: top;

    }

    .member-info {
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	min-height: 140px;
    	padding: 0 1em;
    }
</style>